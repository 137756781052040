<template>
    <div class="container-xl" v-if="!$store.getters.isForbidden">
      <div class="card">
        <div class="card-header border-0 justify-content-between">
          <div class="card-title">{{ $route.name }} List</div>
          <div class="flex flex-row mb-2 pr-2 float-right">
            <el-input v-if="filter.search_by === 'voucher_name' || filter.search_by === 'vendor_name'" v-model="filter.search_keyword" size="small" placeholder="Search" style="width: 150px; margin-right: 0.5rem" clear="mr-2" clearable @clear="filter.page = 1; searchHandler();" />
            <el-select size="small" v-else v-model="filter.merchant_id" placeholder="Merchant" class="mr-2" filterable remote :remote-method="searchMerchant">
              <el-option
                v-for="item in merchantList"
                :key="item.merchant_id"
                :label="item.merchant_name"
                :value="item.merchant_id">
              </el-option>
            </el-select>
            <el-select size="small" @change="filter.search_keyword = ''" v-model="filter.search_by" placeholder="Filter type" clear="mr-2" style="width: 150px; margin-right: 0.5rem">
              <el-option v-for="(item, index) in search_options" :value="item.value" :label="item.label" :key="index"></el-option>
            </el-select>
            <el-select size="small" v-model="filter.status_filter" placeholder="Voucher Status" class="mr-2" filterable style="width: 100px">
              <el-option
                v-for="item in statusOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
            <el-select size="small" v-model="filter.stock_type" placeholder="Stock Type" class="mr-2" filterable style="width: 100px">
              <el-option
                v-for="item in voucher_type_options"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
            <!-- voucher_name -->
            <el-button type="primary" @click="filter.page = 1; searchHandler();" size="small"><font-awesome-icon icon="search"/> Search</el-button>
            <el-button type="primary" @click="clickAddVoucher" size="small"><font-awesome-icon icon="plus"/> Create Voucher</el-button>
            <el-button type="primary" @click="exportCSV" size="small">Export Data</el-button>
            <el-button type="primary" @click="syncVendor" size="small">Sync Vendor</el-button>
         </div>
        </div>
         <b-modal size="lg" v-model="showDetail" title="Sync Vendor" hide-footer>
            <b-form @submit.prevent="doSyncData" @reset="showDetail">
              <b-form-group label="Vendor Name">
                <el-select v-model="selectForm.vendor_id" placeholder="Select voucher vendor" class="mr-2 w-full">
                  <el-option
                    v-for="item in filteredVendors"
                    :key="item.vendor_id"
                    :label="item.vendor_name"
                    :value="item.vendor_id">
                  </el-option>
                </el-select>
              </b-form-group>
              <div class="d-flex flex-row float-right">
                <el-button @click="doSyncData" class="mr-2 ml-2" size="small" type="primary">Sync</el-button>
                <el-button @click="closeModal" size="small" class="mr-2">Cancel</el-button>
              </div>
            </b-form>
          </b-modal>
        <div class="table-responsive">
          <table class="table card-table text-nowrap table-vcenter">
            <thead>
              <tr>
                <th width="20%">Merchant Name</th>
                <th width="40%">Voucher Name</th>
                <th width="20%">Vendor</th>
                <th>Stock</th>
                <th>Stock Type</th>
                <!-- <th width="10%">Start From</th>
                <th width="10%">End At</th> -->
                <th width="10%">Price</th>
                <th width="10%">Base Price</th>
                <th>Status</th>
                <th>#</th>
              </tr>
            </thead>
            <tbody v-loading="isLoading">
              <tr v-for="(item, i) in list" :key="i">
                <td>{{ item.merchant.merchant_name }}</td>
                <td>{{ item.voucher_name }}</td>
                <td>{{ item.vendor ? item.vendor.vendor_name : "-" }}</td>
                <td class="text-nowrap text-muted">{{ item.voucher_stock }}</td>
                <td>
                  {{ item.voucher_purchase_duration ? 'API' : 'Inhouse' }}
                </td>
                <!-- <td class="text-nowrap text-muted">{{ item.voucher_start_at_str }}</td>
                <td class="text-nowrap text-muted">{{ item.voucher_end_at_str }}</td> -->
                <td class="text-nowrap text-muted">{{ item.voucher_price_str }}</td>
                <td class="text-nowrap text-muted">{{ item.voucher_base_price_str }}</td>
                <td class="text-nowrap text-muted">
                  <span class="tag" :class="item.status_color">{{ item.status_str }}</span>
                </td>
                <td>
                  <button class="btn btn-secondary btn-sm dropdown-toggle" data-toggle="dropdown">Actions</button>
                  <div class="dropdown-menu dropdown-menu-right dropdown-menu-arrow p-2" style="width: 200px">
                    <a class="dropdown-item btn-sm cursor-pointer" @click="clickEditVoucher(item)">
                      <font-awesome-icon :icon="['fas', 'pen']" class="text-primary"/>&nbsp; Edit Voucher
                    </a>
                    <a class="dropdown-item btn-sm cursor-pointer" @click="clickDuplicateVoucher(item)">
                      <font-awesome-icon :icon="['fas', 'copy']" class="text-brand"/>&nbsp; Duplicate Voucher
                    </a>
                    <a class="dropdown-item btn-sm cursor-pointer" @click="toggleVoucherStatus(item)">
                      <font-awesome-icon :icon="['fas', 'power-off']" class="text-danger"/>&nbsp; Enable/Disable Voucher
                    </a>
                    <a v-if="item.voucher_status !== 2" class="dropdown-item btn-sm cursor-pointer" @click="archiveVoucher(item)">
                      <font-awesome-icon :icon="['fas', 'trash']" class="text-danger"/>&nbsp; Archive
                    </a>
                  </div>
                  <!-- <button @click="clickEditVoucher(item)" title="Edit Voucher" type="button" class="btn btn-icon btn-flickr btn-sm mr-2"><font-awesome-icon icon="pen"/></button>
                  <button @click="toggleVoucherStatus(item)" title="Toggle Voucher Status" type="button" class="btn btn-icon btn-danger btn-sm"><font-awesome-icon icon="power-off"/></button> -->
                </td>
              </tr>
            </tbody>
          </table>
          <el-empty description="No data found" v-if="total_rows == 0" :image="emptyStateImage"></el-empty>
        </div>
        <pagination
          :page="filter.page"
          :per_page="filter.per_page"
          :list="list"
          :total_rows="total_rows"
          @pageChange="pageChangeHandler"/>
      </div>
    </div>
</template>
<script>
/* eslint-disable radix */
import { isEqual, isEmpty, clone } from 'lodash';
import moment from 'moment';
import useVuelidate from '@vuelidate/core';
import { required, maxLength } from '@vuelidate/validators';
import { GET_VOUCHERS, UPDATE_VOUCHERS, VOUCHER_VENDOR, SYNC_VENDOR } from '@/store/modules/vouchers';
import { GET_MERCHANTS } from '@/store/modules/merchants';
import emptyStateImage from '@/assets/images/empty-state.png';
import popupErrorMessages from '@/library/popup-error-messages';
import Pagination from '../components/Pagination.vue';
import axios from "axios";

export default {
  components: { Pagination },
  name: 'Vouchers',
  metaInfo: {
    title: 'Vouchers',
  },
  setup() {
    return {
      v$: useVuelidate(),
    };
  },
  data() {
    return {
      vendor: [],
      showDetail: false,
      selectForm: {
        vendor_id: ''
      },
      emptyStateImage,
      merchantList: [],
      merchant_id: '',
      filter_merchant_loading: false,
      per_page: 20,
      total_rows: 0,
      page: 1,
      list: [],
      isLoading: true,
      loader: null,
      search_keyword: '',
      search_by: 'merchant_id',
      search_merchant_keyword: '',
      showModalCreate: false,
      modalType: 'add',
      loading: {
        create_voucher: false,
        detail: false,
      },
      voucher_status: '',
      statusOptions: [
        {
          value: '',
          label: 'All',
        },
        {
          value: 'active',
          label: 'Active',
        },
        {
          value: 'inactive',
          label: 'Inactive',
        },
        {
          value: 'archive',
          label: 'Archive',
        },
      ],
      search_options: [
        {
          value: 'merchant_id',
          label: 'Merchant',
        },
        {
          value: 'voucher_name',
          label: 'Voucher name',
        },
        {
          value: 'vendor_name',
          label: 'Vendor name',
        },
      ],
      voucher_type_options: [
        {
          value: '',
          label: 'All',
        },
        {
          value: 'inhouse',
          label: 'Inhouse',
        },
        {
          value: 'api',
          label: 'API',
        },
      ],
      form: {
        voucher_name: '',
        voucher_description: '',
        voucher_banner: '',
        voucher_photo_url: '',
        voucher_price: 0,
        voucher_base_price: 0,
        voucher_sku: '',
        voucher_start_at: moment().format('YYYY-MM-DD'),
        voucher_end_at: moment().add(30, 'days').format('YYYY-MM-DD'),
        is_recommend_deal: 1,
        voucher_status: 1,
      },
      filter: {
        page: 1,
        per_page: 20,
        search_by: 'merchant_id',
        search_keyword: '',
        status_filter: '',
        stock_type: '',
      },
    };
  },
  validations() {
    return {
      form: {
        voucher_name: {
          required,
          maxLength: maxLength(255),
        },
        voucher_description: {
          required,
        },
        voucher_banner: {
          required,
        },
        voucher_photo_url: {
          required,
        },
        voucher_price: {
          required,
        },
        voucher_base_price: {
          required,
        },
        voucher_start_at: { required },
        voucher_end_at: { required },
      },
    };
  },
  computed: {
    modalTitle() {
      return this.modalType === 'add' ? 'Create New Voucher' : 'Edit Voucher';
    },
    showingFrom() {
      return this.total_rows ? ((this.filter.page - 1) * this.per_page) + 1 : 0;
    },
    showingUntil() {
      if (this.total_rows) {
        if (this.list.length < this.per_page) {
          return this.total_rows;
        }
        return this.filter.page * this.per_page;
      }
      return 0;
    },
    filteredVendors() {
      return this.vendor.filter(v => v.vendor_code === 'mobile_pulsa' || v.vendor_code === 'ultra_voucher');
    }
  },
  async mounted() {
    const loader = this.$loading.show();
    if (!isEmpty(this.$route.query)) {
      this.filter = clone(this.$route.query);
      this.filter.page = this.$route.query.page ? parseInt(this.$route.query.page) : 1;
    }
    await this.getList();
    this.getMerchantList();
    await this.getVoucherVendor();
    loader.hide();
  },
  methods: {
    pageChangeHandler(page) {
      this.filter.page = page;
      this.updateURLQuery();
    },
    updateURLQuery() {
      if (!isEqual(this.$route.query, this.filter)) {
        this.$router.replace({
          name: this.$route.name,
          query: this.filter,
        }).catch(() => {});
      }
    },
    async searchHandler() {
      this.filter.page = 1;
      if (this.search_by === 'merchant_id') {
        this.search_keyword = this.merchant_id;
      }
      // if (this.merchant_id !== null) {
      // this.search_by = 'merchant_id';
      // this.search_keyword = this.merchant_id;
      // }
      await this.getList();
    },
    searchMerchant(query) {
      this.search_merchant_keyword = query;
      this.getMerchantList();
    },
    async getList() {
      this.isLoading = true;
      this.updateURLQuery();
      await this.$store.dispatch(GET_VOUCHERS, {
        ...this.filter,
      }).catch(() => {});
      const { rows, count } = this.$store.getters.vouchers;
      this.total_rows = count || 0;
      this.list = rows;
      this.isLoading = false;
    },
    async getMerchantList() {
      this.filter_merchant_loading = true;
      await this.$store.dispatch(GET_MERCHANTS, {
        search_by: 'merchant_name',
        search_keyword: this.search_merchant_keyword,
      }).catch(() => {});
      this.merchantList = [{
        merchant_name: 'All',
        merchant_id: '',
      }];
      const { rows } = this.$store.getters.merchants;
      this.merchantList.push(...rows);
      this.filter_merchant_loading = false;
    },
    async clickEditVoucher(item) {
      this.$router.push({
        name: 'Edit Voucher',
        params: {
          id: item.voucher_id,
          action: 'edit',
        },
      });
    },
    clickAddVoucher() {
      this.$router.push({
        name: 'Add Voucher',
        params: {
          action: 'add',
        },
      });
    },
    async exportCSV() {
      try {
        //by search
        const searchData = {
          search_by: this.search_by,
          search_keyword: this.search_keyword,
          page: 1,
          per_page: 100,
        };
        //header
        const headers = [
          "Merchant Name",
          "Voucher Name",
          "Vendor",
          "Stock",
          "Stock Type",
          "Price",
          "Base Price",
          "Status",
        ];

        let allData = [];

        // loop
        while (true) {
          const response = await axios.get("/api/vouchers", {
            responseType: "blob",
            params: searchData,
          });

          if (!response.data) {
            console.error("response.data is undefined...");
            break;
          }

          const vouchersBlob = response.data;
          const vouchersData = await new Response(vouchersBlob).json();

          //export data
          const currentData = vouchersData.data.rows.map((voucher) => [
            voucher.merchant.merchant_name,
            voucher.voucher_name,
            voucher.vendor ? voucher.vendor.vendor_name : null,
            voucher.voucher_stock,
            // voucher.vendor.vendor_name,
            voucher.voucher_purchase_duration ? "API" : "Inhouse",
            voucher.voucher_price,
            voucher.voucher_base_price,
            // voucher.voucher_status === 1 ? 0 : 1
            voucher.voucher_status === 1 ? "Active" : "Inactive",
          ]);

          allData = allData.concat(currentData);

          if (currentData.length < searchData.per_page) {
            break;
          }

          searchData.page++;
        }

        const csvContent = [headers, ...allData]
          .map((row) => row.join(","))
          .join("\n");

        const blob = new Blob([csvContent], { type: "text/csv" });

        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = "exported_vouchers.csv";

        link.click();

        window.URL.revokeObjectURL(link.href);
      } catch (error) {
        console.error("ERRORRRRRRRR", error);
        this.$message({
          title: "Export Error",
          type: "error",
          message: "Error. pls check the console for details.",
        });
      }
    },
    clickDuplicateVoucher(item) {
      this.$router.push({
        name: 'Add Voucher',
        params: {
          action: 'add',
        },
        query: {
          dup_source: item.voucher_id,
        },
      });
    },
    closeModalCreate() {
      this.showModalCreate = false;
    },
    onSubmitVoucher() {},
    toggleVoucherStatus(item) {
      let msg = 'Are you sure want to activate this voucher?';
      if (item.voucher_status === 1) {
        msg = 'Are you sure want to inactivate this voucher?';
      }
      this.$confirm(msg, 'Confirmation', {
        type: 'info',
        beforeClose: async (action, instance, done) => {
          if (action === 'confirm') {
            done();
            instance.confirmButtonLoading = true;
            await this.$store.dispatch(UPDATE_VOUCHERS, {
              id: item.voucher_id,
              data: {
                voucher_status: item.voucher_status === 1 ? 0 : 1,
              },
            }).then(() => {
              this.$message({
                title: 'Success',
                type: 'success',
                message: 'Voucher updated',
              });
              this.getList();
            }).catch((err) => {
              popupErrorMessages(err.response.data);
            });
            instance.confirmButtonLoading = false;
            return;
          }
          done();
        },
      }).catch(() => {});
    },
    archiveVoucher(item) {
      let msg = 'Are you sure want to archive this voucher?';
      // if (item.voucher_status === 2) {
      //   msg = 'Are you sure want to unarchive this voucher?';
      // }
      this.$confirm(msg, 'Confirmation', {
        type: 'info',
        beforeClose: async (action, instance, done) => {
          if (action === 'confirm') {
            done();
            instance.confirmButtonLoading = true;
            await this.$store.dispatch(UPDATE_VOUCHERS, {
              id: item.voucher_id,
              data: {
                // voucher_status: item.voucher_status === 2 ? '' : 2,
                voucher_status: 2,
              },
            }).then(() => {
              this.$message({
                title: 'Success',
                type: 'success',
                message: 'Voucher updated',
              });
              this.getList();
            }).catch((err) => {
              popupErrorMessages(err.response.data);
            });
            instance.confirmButtonLoading = false;
            return;
          }
          done();
        },
      }).catch(() => {});
    },
    closeModal() {
      this.showDetail = false;
      this.selectForm = {
        vendor_id: '',
      }
    },
    syncVendor(item) {
      this.showDetail = true;
    },
    async doSyncData() {
      this.$confirm('You will synchronize this vendor. Make sure you have input data correctly. Continue?', 'Confirmation', {
        type: 'info',
        beforeClose: async (action, instance, done) => {
          if (action === 'confirm') {
            done();
            const payload = {
              vendor_id: this.selectForm.vendor_id,
            };
            await this.$store.dispatch(SYNC_VENDOR, payload)
              .then(() => {
                this.$message({
                  title: 'Success',
                  type: 'success',
                  message: 'Vendor successfully synchronized',
                });
                this.showDetailID = false;
                this.getList();
                this.closeModal();
              }).catch((err) => {
                popupErrorMessages(err.response.data);
              });
            return;
          }
          done();
        },
      }).catch(() => {});
    },
    async getVoucherVendor() {
      await this.$store.dispatch(VOUCHER_VENDOR, {});
      this.vendor = this.$store.getters.vendors.rows;
    },
  }
};
</script>
<style>
.child1-sticky
{
  position:sticky;
  right: 0px;
  width: 40px;
  background: #fff;
}
</style>
